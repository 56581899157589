import { mergeDeep } from "./mergeDeep";
import defaultStyles from "./defaultStyles";
import Raven from "./raven";
import "babel-polyfill";

export const HostedForm = class HostedForm {
  constructor(config) {
    this.config = this.setConfig(config);

    Raven.setExtraContext({
      public_token: this.config.apiKey
    });

    Raven.context(() => {
      if (this.config.styles === undefined) {
        this.config.styles = defaultStyles;
      }

      this.listenForMessages();
    });
  }

  setConfig(config) {
    return mergeDeep({}, this.defaultConfig, config);
  }

  get defaultConfig() {
    return {
      id: "#hosted-form",
      formSelector: "#hosted-payment-form",
      apiKey: undefined,
      autoSubmit: false,
      showMessages: true,
      onError: undefined,
      onSuccess: undefined,
      onCancel: undefined,
      formFieldPrefix: "embed",
      submitButtonText: "Submit",
      showCaptcha: false,
      placeholders: {
        cardName: "Cardholder name",
        cardNumber: "Card number",
        cardZip: "Zip code",
        cardExpiry: "MM/YY",
        cardSecurity: "321",
        streetAddress: "Street address"
      },
      helpText: {
        cardName: "Please provide a name",
        cardNumber: "Please provide a card number",
        cardZip: "Please provide a zip code",
        cardExpiry: "Please provide a valid date",
        cardSecurity: "CVC/CVV",
        streetAddress: "Please provide a street address"
      },
      showLabels: false,
      showHelp: false,
      showSecurityCode: false,
      showStreetAddress: false,
      disableAfterSubmit: true,
      showCancelButton: false,
      buttonGroupAlignment: "left"
    };
  }

  get element() {
    return document.getElementById(this.elementId);
  }

  get disableAfterSubmit() {
    return this.config.disableAfterSubmit;
  }

  get shouldAutoSubmitForm() {
    return this.config.autoSubmit;
  }

  get shouldShowMessages() {
    return this.config.showMessages;
  }

  get shouldShowLabels() {
    return this.config.showLabels;
  }

  get shouldShowHelp() {
    return this.config.showHelp;
  }

  get showCancelButton() {
    return this.config.showCancelButton;
  }

  get shouldShowSecurityCode() {
    return this.config.showSecurityCode;
  }

  get shouldShowStreetAddress() {
    return this.config.showStreetAddress;
  }

  get buttonGroupAlignment() {
    return this.config.buttonGroupAlignment;
  }

  get elementId() {
    return this.config.id.replace("#", "");
  }

  get css() {
    return this.config.styles;
  }

  get placeholders() {
    return this.config.placeholders;
  }

  get helpText() {
    return this.config.helpText;
  }

  help(key) {
    return this.config.helpText[key];
  }

  set apiKey(apiKey) {
    this.config.apiKey = apiKey;
  }

  set onSuccess(callback) {
    this.config.onSuccess = callback;
  }

  set onCancel(callback) {
    this.config.onCancel = callback;
  }

  set onError(callback) {
    this.config.onError = callback;
  }

  mount() {
    this.setIFrameId();
    Raven.context(() => {
      this.iframe = this.createFrame();
      this.element.appendChild(this.iframe);
    });
  }

  enableForm() {
    this.iframe.contentWindow.postMessage(
      JSON.stringify({ type: "ENABLE_FORM" }),
      `${process.env.APP_URL}`
    );
  }

  destroy() {
      if (this.iframe) {
          this.element.removeChild(this.iframe);
      }
  }

  disableForm() {
    this.iframe.contentWindow.postMessage(
      JSON.stringify({ type: "DISABLE_FORM" }),
      `${process.env.APP_URL}`
    );
  }

  createFrame() {
    // Set default min-height based on vanilla styling
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    let minHeight = vw >= 772 ? 325 : 365;

    if (!this.shouldShowSecurityCode) {
      minHeight -= 70;
    }

    if (!this.shouldShowStreetAddress) {
      minHeight -= 70;
    }

    let frame = document.createElement("iframe");

    frame.id = this.iFrameId;
    // We need to be able to set the first part of the src dynamically
    frame.setAttribute(
      "src",
      `${process.env.APP_URL}/iframe#${this.encodeOptions()}`
    );
    frame.setAttribute("allowtransparency", "true");
    frame.setAttribute("frameBorder", "0");
    frame.setAttribute("scrolling", "no");
    frame.style.cssText =
      "background-attachment: none !important; background-origin: none !important; background-clip: none !important; background-color: transparent !important; border: none !important; overflow: hidden !important; margin: 0 auto !important; padding: 0 !important; width: 100% !important; min-height: " + minHeight + "px";

    return frame;
  }

  encodeOptions() {
    let apiKey = this.config.apiKey;
    let styles = this.config.styles;
    let autoSubmit = this.shouldAutoSubmitForm;
    let showMessages = this.shouldShowMessages;
    let showCancelButton = this.showCancelButton;
    let buttonGroupAlignment = this.buttonGroupAlignment;
    let showLabels = this.shouldShowLabels;
    let showHelp = this.shouldShowHelp;
    let showSecurityCode = this.shouldShowSecurityCode;
    let showStreetAddress = this.shouldShowStreetAddress;
    let placeholders = this.placeholders;
    let helpText = this.helpText;
    let disableAfterSubmit = this.disableAfterSubmit;
    let submitButtonText = this.config.submitButtonText;
    let showCaptcha = this.config.showCaptcha;

    return encodeURIComponent(
      JSON.stringify({
        apiKey,
        styles,
        autoSubmit,
        showMessages,
        showCancelButton,
        buttonGroupAlignment,
        showLabels,
        showHelp,
        showSecurityCode,
        showStreetAddress,
        placeholders,
        helpText,
        disableAfterSubmit,
        submitButtonText,
        showCaptcha
      })
    );
  }

  callCallback = event => {
    if (
      process.env.APP_ENV !== "testing" &&
      event.origin !== process.env.APP_URL
    ) {
      return null;
    }

    if (event.data.type === 'resize') {
      document.getElementById(this.iFrameId).style.minHeight = event.data.height + 'px';
    }

    if (event.data.type === "cancel" && this.config.onCancel !== undefined) {
      this.config.onCancel();
    }

    if (
      !this.shouldAutoSubmitForm &&
      event.data.type === "success" &&
      this.config.onSuccess !== undefined
    ) {
      return this.config.onSuccess(event.data);
    }

    if (this.shouldAutoSubmitForm === true && event.data.type === "success") {
      const parentForm = document.querySelector(this.config.formSelector);
      if (parentForm !== null) {
        const token = document.createElement("input");

        token.id = "card_token";
        token.type = "hidden";
        token.name = "card_token";
        token.value = event.data.token;

        parentForm.appendChild(token);

        Object.keys(event.data).forEach(key => {
          if (event.data[key] !== undefined) {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = `${this.config.formFieldPrefix}[${key}]`;
            input.value = event.data[key];
            parentForm.appendChild(input);
          }
        });

        parentForm.submit();
      }
    }

    if (event.data.type === "error" && this.config.onError !== undefined) {
      return this.config.onError({
        message: event.data.messages,
        errors: event.data.errors
      });
    }
  };

  listenForMessages() {
    window.addEventListener("message", this.callCallback, false);
  }

  setIFrameId() {
    this.iFrameId =
      "hostedform-" +
      Math.random()
        .toString(36)
        .substr(2, 9);
  }
};

export function factory(config) {
  return new HostedForm(config);
}

function factoryWithWarning(config) {
  console.warn(
    '"HostedPay" will depreciated and will be removed in the future. Please use "HostedForm" instead."'
  );

  if (config.apiKey) {
    Raven.setUserContext({
      id: config.apiKey
    });
    Raven.captureMessage(
      `${config.apiKey} is using HostedPay instead of HostedForm`,
      {
        level: "info"
      }
    );
  }

  return factory(config);
}

export default {
  HostedForm
};
(function() {
  this.HostedForm = factory;
  this.HostedPay = factoryWithWarning;
}.call(window));
