export default {
  base: {
    fontSize: 16,
    lineHeight: 1.5,
    padding: '0 5px',
  },
  error: {
    display: 'block',
    background: '#E3342F',
    fontSize: 14,
    color: 'white',
    padding: '7px 19px',
    textAlign: 'center',
    borderRadius: '4px',
    marginBottom: 3,
  },
  success: {
    display: 'block',
    background: '#38C172',
    fontSize: 14,
    color: 'white',
    padding: '7px 19px',
    textAlign: 'center',
    borderRadius: '4px',
    marginBottom: 3,
  },
  formGroup: {
    display: 'block',
    padding: '3px 0',
    borderRadius: '4px',
    marginBottom: 3,
  },
  label: {
    display: 'block',
    color: '#666',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  helpText: {
    fontSize: 13,
    fontStyle: 'italic',
    color: '#aaa',
  },
  helpError: {
    fontSize: 13,
    fontStyle: 'italic',
    color: '#E3342F',
  },
  input: {
    ':focus': {
      outline: 'none',
      border: '1px solid #3490DC',
    },
    color: '#555',
    '::-webkit-input-placeholder': { color: '#777' },
    '::-moz-placeholder': { color: '#777' },
    ':-ms-input-placeholder': { color: '#777' },
    ':-moz-placeholder': { color: '#777' },
    display: 'block',
    border: '1px solid #ddd',
    paddingLeft: 9,
    paddingRight: 9,
    height: '37px',
    borderRadius: '4px',
    marginBottom: 3,
    width: '100%',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)',
  },
  button: {
    backgroundColor: '#3490DC',
    color: 'white',
    borderRadius: '4px',
    padding: '10px 20px',
    fontSize: 16,
    ':hover': {
      backgroundColor: '#2779BD',
    },
    ':focus': {
      backgroundColor: '#2779BD',
      outline: 'none',
    },
    ':active': {
      backgroundColor: '#2779BD',
    },
  },
  cancel: {
    backgroundColor: '#fff',
    color: '#888',
    borderRadius: '4px',
    padding: '10px 20px',
    marginRight: '15px',
    fontSize: 16,
    ':hover': {
      backgroundColor: '#fff',
      color: '#a6a6a6',
    },
    ':focus': {
      backgroundColor: '#fff',
      color: '#a6a6a6',
      outline: 'none',
    },
    ':active': {
      backgroundColor: '#fff',
      color: '#a6a6a6',
    },
  },
}
